<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'Management' }">Management</router-link>
            | <span class="title"> Approval Management</span>
          </h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="mt-10">
          <v-col class="mx-auto" cols="12" md="12">
            <approval-table
              @editBand="editBands"
              :reload="reload"
            ></approval-table>
          </v-col>
        </v-row>
      </v-container>
      <approve-user
        :editDialog="isEdit"
        :currentBand="currentBand"
        @closeDialog="resetData"
        @reloadPage="reload = !reload"
      />
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    approvalTable: () => import('@/components/team-managment/ApproverTable'),
    approveUser: () => import('@/components/team-managment/ApproveUser'),
  },
  props: {
    source: String,
  },
  data: () => ({
    isEdit: false,
    drawer: null,
    snackback: false,
    currentBand: {},
    reload: false,
  }),
  methods: {
    editBands(band) {
      this.isEdit = true
      this.currentBand = band
    },
    resetData() {
      this.isEdit = false
      this.currentBand = {}
    },
  },
}
</script>
